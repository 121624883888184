import { Trans } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import Button from '@/core/components/Button';
import { useIsLg } from '@/core/hooks/useMediaQuery';
import useOpenGive from '@/core/hooks/useOpenGive';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import Store from '@/core/lib/new-architecture/store';
import { useRouterContext } from '@/core/lib/router/router.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

const numberToSpacedString = (users: number | undefined) => {
  if (!users) {
    return '';
  }
  const numbers = [];
  let res = users;

  while (res / 1000 > 1) {
    const dataToString = res.toString();
    numbers.push(dataToString.substring(dataToString.length - 3));
    res = Math.floor(res / 1000);
  }
  numbers.push(res.toString());

  return numbers.reverse().join('\u00a0');
};

const Hero: React.FC = () => {
  const { t } = useTranslationContext(['common']);
  const { isLoggedIn } = useAuthContext();
  const { currentPage, push } = useRouterContext();
  const [showTitleAndParagraphDesktop, setShowTitleAndParagraphDesktop] = useState<boolean>(false);
  const isLg = useIsLg();

  const { onOpenGive } = useOpenGive();
  const { send } = useAnalyticsContext();

  const onClickOpenGive = async () => {
    await send({ event: AnalyticsEventType.CLICK_GIVE, where: 'Hero' });
    onOpenGive();
  };

  const onOpenAuth = async () => {
    await send({ event: AnalyticsEventType.CLICK_AUTH, where: 'Hero + Give' });
    const clone = currentPage.clone({ openGive: true });

    currentPage.updateCommon(prev => ({ ...prev, openAuth: true, redirectTo: clone.resolve() }));
    push(currentPage);
  };

  const { data } = Store.home.useHome();
  const totalUsers = data?.getUserAccountsNumber();

  const donors = numberToSpacedString(totalUsers);

  useEffect(() => {
    setShowTitleAndParagraphDesktop(isLg);
  }, [isLg]);

  return (
    <section className="flex h-fit w-full justify-between bg-bg-button-primary-default px-4 py-6 md:px-6 lg:py-9 xl:px-24">
      <div className="inline-flex w-full flex-col justify-center gap-6 lg:gap-12">
        <div className="flex flex-col items-start gap-4 lg:gap-6">
          <h1 className="font-abril text-33 leading-9 text-content-button-primary lg:text-64 lg:leading-18">
            <Trans i18nKey="components.hero.title" key="title" components={{ 1: showTitleAndParagraphDesktop ? <br /> : <>&nbsp;</> }} />
          </h1>
          <p className="text-title font-semibold text-content-button-primary">
            <Trans
              values={{ donors }}
              i18nKey="components.hero.catchphrase"
              components={[<span className="text-content-tag-status-moderation" key="catchphrase" />, showTitleAndParagraphDesktop ? <br /> : <>&nbsp;</>]}
            />
          </p>
        </div>
        <div className="w-max">
          {isLoggedIn ? (
            <Button onClick={onClickOpenGive} variant="give" size="large" iconLeft="add">
              {t('components.hero.give', { ns: 'common' })}
            </Button>
          ) : (
            <Button onClick={onOpenAuth} variant="give" size="large" iconLeft="add">
              {t('components.hero.give', { ns: 'common' })}
            </Button>
          )}
        </div>
      </div>
      <div className="hidden w-full lg:block">
        <img loading="lazy" decoding="async" className="mx-auto lg:max-h-[444px]" src="/assets/illustration/hero_illustration.png" alt={t('components.hero.illustration-alt', { ns: 'common' })} />
      </div>
    </section>
  );
};

export default Hero;
